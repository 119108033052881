<template>
  <div>
    <div id="in-exclusion-area">
      <div class="mt-20px inclusion-exclusion-block-title" v-if="inclusionLength > 0">
        {{ `Inclusion of ${currentEvent.name}` }}
      </div>
      <div id="inclusion-event-area" v-if="inclusionLength > 0">
        <div
          class="inclusion-block"
          v-for="(event, key) in event.inclusionList"
          :key="key"
          :class="{ 'inclusion-block-left-line': inclusionLength - 1 !== key }"
        >
          <div v-if="inclusionLength - 1 === key && inclusionLength > 1" class="last-inclusion-corner-line"></div>
          <div v-if="inclusionLength - 1 === key && inclusionLength === 1" class="only-inclusion-corner-line"></div>
          <div v-if="inclusionLength > 1 && key !== 0">
            <div class="AND-word ml-20px mt-5px">AND</div>
          </div>
          <div class="d-flex justify-content-between align-items-end mb-5px mt-5px">
            <div class="d-flex align-items-center">
              <div class="corner-line" v-if="inclusionLength - 1 !== key"></div>
              <div
                class="area-title"
                :class="{ 'ml-10px': inclusionLength - 1 !== key, 'ml-20px': inclusionLength - 1 === key }"
              >
                Inclusion
              </div>
            </div>
            <div class="d-flex">
              <div class="d-flex align-items-center delete-button ml-10px" @click.stop="deleteInclusionEvent(key)">
                <b-img src="@/assets/images/icon_delete_event.svg" />
                <div class="delete-text ml-5px">Delete</div>
              </div>
              <div class="d-flex align-items-center clear-button ml-10px" @click.stop="clearInclusionEvent(key)">
                <b-img src="@/assets/images/icon_clear_event.svg" />
                <div class="clear-text ml-5px">Clear</div>
              </div>
            </div>
          </div>
          <div class="event-block ml-20px">
            <EventContainer
              :index="index"
              :eventType="EventType.INCLUSION"
              :event="event"
              :path="path.concat(['inclusionList', key])"
              :ref="`inclusion${key}`"
            />
          </div>
        </div>
      </div>
      <div class="mt-20px exclusion-exclusion-block-title" v-if="exclusionLength > 0">
        {{ `Exclusion of ${currentEvent.name}` }}
      </div>
      <div id="exclusion-event-area">
        <div
          class="exclusion-block"
          v-for="(event, key) in event.exclusionList"
          :key="key"
          :class="{ 'exclusion-block-left-line': exclusionLength - 1 !== key }"
        >
          <div v-if="exclusionLength - 1 === key && exclusionLength > 1" class="last-exclusion-corner-line"></div>
          <div v-if="exclusionLength - 1 === key && exclusionLength === 1" class="only-exclusion-corner-line"></div>
          <div v-if="exclusionLength > 1 && key !== 0">
            <div class="AND-word ml-20px mt-5px">AND</div>
          </div>
          <div class="d-flex justify-content-between align-items-end mb-5px mt-5px">
            <div class="d-flex align-items-center">
              <div class="exclusion-corner-line" v-if="exclusionLength - 1 !== key"></div>
              <div
                class="area-title"
                :class="{ 'ml-10px': exclusionLength - 1 !== key, 'ml-20px': exclusionLength - 1 === key }"
              >
                Exclusion
              </div>
            </div>
            <div class="d-flex">
              <div class="d-flex align-items-center delete-button ml-10px" @click.stop="deleteExclusionEvent(key)">
                <b-img src="@/assets/images/icon_delete_event.svg" />
                <div class="delete-text ml-5px">Delete</div>
              </div>
              <div class="d-flex align-items-center clear-button ml-10px" @click.stop="clearExclusionEvent(key)">
                <b-img src="@/assets/images/icon_clear_event.svg" />
                <div class="clear-text ml-5px">Clear</div>
              </div>
            </div>
          </div>
          <div class="event-block ml-20px">
            <EventContainer
              :index="index"
              :eventType="EventType.EXCLUSION"
              :event="event"
              :path="path.concat(['exclusionList', key])"
              :ref="`exclusion${key}`"
            />
          </div>
        </div>
      </div>
      <div id="fix-head-area">
        <div id="adder-container">
          <div class="d-inline-block event-adder" id="inclusion-adder" @click="addInclusionEvent">+ Inclusion</div>
          <div class="d-inline-block event-adder" id="exclusion-adder" @click="addExclusionEvent">+ Exclusion</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EventType } from '@/utils/conditions/core/Interface';
import {
  defaultInclusionEventWithoutFirstOccurrence,
  defaultExclusionEventWithoutFirstOccurrence,
} from '@/utils/event/initState';
import { EventUpdateMixin, EventSearchMixin, ClickMixin } from '@/mixins';
import { cloneDeep } from 'lodash';

export default {
  name: 'InExclusion',
  components: {
    EventContainer: () => import('@/components/byEvent/event/EventContainer.vue'),
  },
  mixins: [EventUpdateMixin, EventSearchMixin, ClickMixin],
  computed: {
    inclusionLength() {
      return this.event.inclusionList.length;
    },
    exclusionLength() {
      return this.event.exclusionList.length;
    },
  },
  data() {
    return {
      EventType,
    };
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    path: {
      type: Array,
      default: () => [],
    },
    event: {
      type: Object,
      default: null,
    },
  },
  methods: {
    addInclusionEvent() {
      const event = cloneDeep(this.event);
      event.inclusionList.push({
        ...cloneDeep(defaultInclusionEventWithoutFirstOccurrence),
        name: `${this.event.name}-inclusion-${this.inclusionLength}`,
      });
      this.updateEventWithPath(event, this.path);
      this.cohortStudyTrackClick('addInclusionEvent');
      this.$nextTick(() => {
        const containerDiv = document.querySelector(
          `#${this.path.concat(['inclusionList', this.event.inclusionList.length - 1]).join('-')}-${this.index}-${
            EventType.INCLUSION
          }`
        );
        if (containerDiv) {
          containerDiv.scrollIntoView({
            block: 'center',
          });
        }
      });
    },
    addExclusionEvent() {
      const event = cloneDeep(this.event);
      event.exclusionList.push({
        ...cloneDeep(defaultExclusionEventWithoutFirstOccurrence),
      });
      this.updateEventWithPath(event, this.path);
      this.cohortStudyTrackClick('addExclusionEvent');

      this.$nextTick(() => {
        const containerDiv = document.querySelector(
          `#${this.path.concat(['exclusionList', this.event.exclusionList.length - 1]).join('-')}-${this.index}-${
            EventType.EXCLUSION
          }`
        );
        if (containerDiv) {
          containerDiv.scrollIntoView({
            block: 'center',
          });
        }
      });
    },
    deleteInclusionEvent(index) {
      const event = cloneDeep(this.event);
      event.inclusionList.splice(index, 1);
      this.updateEventWithPath(event, this.path);
      this.cohortStudyTrackClick('deleteInclusionEvent');
    },
    deleteExclusionEvent(index) {
      const event = cloneDeep(this.event);
      event.exclusionList.splice(index, 1);
      this.updateEventWithPath(event, this.path);
      this.cohortStudyTrackClick('deleteExclusionEvent');
    },
    clearInclusionEvent(index) {
      this.$refs[`inclusion${index}`][0].setDefaultState();
      this.cohortStudyTrackClick('clearInclusionEvent');
    },
    clearExclusionEvent(index) {
      this.$refs[`exclusion${index}`][0].setDefaultState();
      this.cohortStudyTrackClick('clearExclusionEvent');
    },
  },
};
</script>
<style scoped>
.event-adder {
  padding: 4px 8px;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  height: 28px;
  background: #4d8edc;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0px;
}

.margin-20 {
  margin-top: 20px;
  margin-left: 20px;
}

#adder-container {
  margin-top: 10px;
}

#exclusion-adder {
  margin-left: 10px;
}

#inclusion-event-area {
  margin-left: 20px;
}

#exclusion-event-area {
  margin-left: 20px;
}

.area-title {
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.AND-word {
  width: 5%;
  display: inline-block;
  color: #828282;
}

.seperate-line {
  position: relative;
  bottom: 5px;
  width: 90%;
  display: inline-block;
  border-top: 1px solid #d6d9e1;
}
.icon-close-red {
  margin-left: 5px;
  font-size: 12px;
}
.remove-icon {
  height: 20px;
  cursor: pointer;
  margin-left: 5px;
  position: relative;
  bottom: 2px;
}

.clear-button {
  border: 1px solid #4d8edc;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.clear-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4d8edc;
}

.delete-button {
  border: 1px solid #d65c51;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #d65c51;
}

.inclusion-exclusion-block-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #334e97;
}

.corner-line {
  border-left: 1px solid #4d8edc;
  border-bottom: 1px solid #4d8edc;
  width: 10px;
  height: 6px;
  border-bottom-left-radius: 10px 5px;
}

.exclusion-exclusion-block-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #9646b1;
}

.exclusion-corner-line {
  border-left: 1px solid #9646b1;
  border-bottom: 1px solid #9646b1;
  width: 10px;
  height: 6px;
  border-bottom-left-radius: 10px 5px;
}

.inclusion-block-left-line {
  border-left: 1px solid #4d8edc;
}

.exclusion-block-left-line {
  border-left: 1px solid #9646b1;
}

.last-inclusion-corner-line {
  border-left: 1px solid #4d8edc;
  border-bottom: 1px solid #4d8edc;
  width: 10px;
  height: 53px;
  border-bottom-left-radius: 10px 5px;
  position: absolute;
}

.only-inclusion-corner-line {
  border-left: 1px solid #4d8edc;
  border-bottom: 1px solid #4d8edc;
  width: 10px;
  height: 23px;
  border-bottom-left-radius: 10px 5px;
  position: absolute;
}

.last-exclusion-corner-line {
  border-left: 1px solid #9646b1;
  border-bottom: 1px solid #9646b1;
  width: 10px;
  height: 53px;
  border-bottom-left-radius: 10px 5px;
  position: absolute;
}

.only-exclusion-corner-line {
  border-left: 1px solid #9646b1;
  border-bottom: 1px solid #9646b1;
  width: 10px;
  height: 23px;
  border-bottom-left-radius: 10px 5px;
  position: absolute;
}
</style>
